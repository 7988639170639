/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import UpdateAt from '@beelineloans/cx-library/src/components/layout/UpdateAt';
import { H2, H3, Paragraph } from '@beelineloans/cx-library/src/components/typography';
import { ButtonText } from '../../../../../shared/GatsbyButton';

const Table = styled.table`
  border-collapse: collapse;
`;
const THead = styled.thead``;
const TBody = styled.tbody``;
const Row = styled.tr``;
const Cell = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
`;
const HeadCell = styled.th`
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
`;
const Title = styled(H3)`
  padding-top: 40px;
  text-transform: uppercase;
`;

const PrivacyAndSecurity = () => (
  <NavOnlyTemplate
    headerProps={{
      hideButton: true
    }}
  >
    <SEO path={CONST.LINKS.MAIN.LEGAL.DEFAULT} title="Privacy and security" description="Beeline's privacy and security policies." />
    <UpdateAt date="April 2024" />
    <Container>
      <H2>
        Privacy and security.
        <br />
        <br />
        INTRODUCTION
      </H2>
      <Paragraph>Thanks for choosing Beeline!</Paragraph>
      <Paragraph>
        Beeline Loans, Inc. (“we” or “us”) is committed to protecting and respecting your personal information and privacy when you&apos;re visiting our website, portal or any
        other tools found on our site or mobile applications. We want our services to be safe and enjoyable environments for you.
      </Paragraph>
      <Paragraph>
        We want to transparently explain how and why we gather, store, share and use your personal information and outline the controls and choices you have around when and how you
        choose to share this information.
      </Paragraph>
      <Paragraph>
        This Privacy and Security Notice (the “Privacy Policy”) relates to our use of any personal information you give us through our website, portal or any other tools found on
        our site or mobile applications. To make sure you enjoy our full range of services, we need to collect information about you.
      </Paragraph>
      <Paragraph>We want you to feel comfortable and confident when using our website. Here&apos;s how we do that:</Paragraph>

      <ul>
        <li>We provide you notice of our website information practices.</li>
        <li>We tell you how and why we use web technologies.</li>
        <li>We outline how the personally identifiable information that you give us may be used.</li>
        <li>We give you the ability to update or correct your personally identifiable information.</li>
        <li>We outline the ways we protect your information.</li>
      </ul>

      <Paragraph>We&apos;ll also offer you ways to contact us about our information practices and other aspects of privacy.</Paragraph>
      <Paragraph>
        We&apos;re ridiculously committed to safeguarding your personal information. So, whenever you provide such information, we&apos;ll only ever use it in line with the laws
        concerning the protection of personal information (these laws are referred to collectively in this Privacy Policy as the &quot;data protection laws&quot;). We&apos;ve
        worked hard on this Privacy Policy to make sure it&apos;s clear, transparent and at the leading-edge of privacy guidelines in our industry.
      </Paragraph>

      <Title>YOUR SECURITY AND PRIVACY</Title>

      <Paragraph>
        By using our website, you are consenting to the use of information and agreeing to these guidelines and the other Beeline policies described on our website.
      </Paragraph>
      <Paragraph>
        Because we&apos;re in the business of finance, our websites aren&apos;t for anyone under 18. So, we don&apos;t knowingly attempt to solicit or receive any information from
        children.
      </Paragraph>
      <Paragraph>
        We don&apos;t make our advertising decisions based on political affiliations or in support of, or against, any political or religious group. We firmly believe in the right
        of free speech, however, we also believe we have an obligation to ensure our advertising does not appear in content that is detrimental to our brand values. We won&apos;t
        advertise within programs that consistently exploit excessive or gratuitous violence or sexuality, which demean or denigrate any religion, race, sex or which are otherwise
        lacking in good taste.
      </Paragraph>
      <Paragraph>
        Should our advertising appear within programming content that violates these guidelines we&apos;ll review the content in question and make decisions about the
        appropriateness of that program for future consideration.
      </Paragraph>

      <Title>WE PROVIDE YOU NOTICE OF OUR WEBSITE INFORMATION PRACTICES.</Title>
      <Paragraph>
        To maximize the value of our services, we may request information from you when you visit our website. We won&apos;t share your personal information with outside companies
        for their promotional use, however we do use third party services who may access your information for the purpose of processing your application or loan (think an
        appraiser, or title company).
      </Paragraph>
      <Paragraph>
        When you apply for a home loan, credit reporting agencies may share your information with other companies. As a result, you may get unwanted calls about finance products.
        To stop these calls and emails, call (888) 567-8688 or visit&nbsp;
        <ButtonText to="http://www.optoutprescreen.com" newWindow selected>
          www.optoutprescreen.com
        </ButtonText>
        .
      </Paragraph>
      <Paragraph>Here&apos;s a description of the information we collect and how it&apos;s used/disclosed:</Paragraph>

      <ul>
        <li>
          <b>Tools, applications and registration:</b> We provide a number of ways for you to explore your options for mortgages and loans, so you can determine which options may
          be the most desirable for you. You can engage a number of tools, planners, calculators and other interactive advisors, without providing us your identity.
        </li>
        <li>
          We may also supply the information you provide, on a strictly confidential basis, to service providers, such as title companies, appraisers, credit reporting services,
          etc. who assist us in qualifying, processing, closing and servicing your loan.
        </li>
        <li>
          <b>Credit report:</b> By submitting a loan application or a request to be pre-qualified by us, you authorize us to share your credit report with our affiliates.
        </li>
        <li>
          <b>Electronic communication:</b> We provide you the opportunity to subscribe to email communications. You can unsubscribe by following the instructions provided in each
          communication.
        </li>
        <li>
          <b>Mandatory and optional information:</b> We identify what information is required to fulfill your request. If you choose not to provide us with this information, we
          will not be able to provide you the service you are requesting. As part of your use of our website, we may collect information that may include your name, address, social
          security number, the location and value of the property related to the requested loan products or services, financial information about your assets and income, financial
          accounts, insurance and other information. As part of this process, you consent to Beeline obtaining a credit report and for us to use this information as described in
          this policy. Within Beeline&apos;s online application and process, you may consent to one or more of the following - a soft credit inquiry and/or hard credit inquiry. A
          hard credit inquiry is required by Beeline for the extension of any credit to you. We will also collect information about your activity on the Beeline website and use
          that information as described in this policy.
        </li>
        <li>
          <b>Within the Beeline family:</b> Within the Beeline family, which includes, but is not limited to, our title company affiliates, we may exchange customer information to
          fulfill your request(s) or to provide you with information about other products or services, if you have chosen to receive such communications.
        </li>
        <li>
          <b>Service providers:</b> We employ or use service providers such as title companies, appraisers, consultants, and temporary workers, third party software developers,
          etc. to complete a business process or provide a service on our behalf. For example, we may use service providers to enhance our website technology, deliver products, or
          to send emails. When we employ service providers, we may need to share your personally identifiable information. Service providers are strictly prohibited from using your
          personally identifiable information for purposes other than to act on our behalf. Realtors are not considered our service providers. If you have a realtor, we will update
          them with certain milestones of your transaction, but we will not share your sensitive information with them without your consent. If you do not have a realtor, we may
          ask you if you want us to refer someone to you. Unless you have given us permission to do so, your information will not be given to any realtor with whom you do not have
          an existing relationship.
        </li>
        <li>
          <b>Data providers:</b> We use a number of third party data providers. These providers allow us to provide you with a better experience by bypassing the old-fashioned
          document gathering - and they are listed here: Amazon Web Services, Beeline Title Holdings, Inc. and its subsidiaries, Black Knight Data Analytics, LLC,
          ComplianceEase/SitusAMC, Docutech, Equifax/TWN, Estated, First American, FNMA, Freddie Mac, Google, LenderPrice, LexisNexis, Lodestar, MERSCORP Holdings, Inc., Mortgage
          Electronic Registration System, Inc., Ncontracts/QuestSoft, Nimble Title Holdings, LLC and its subsidiaries, LLC, Plaid, Inc., Qualia, Relcu, ServiceLink, Stellar
          Innovative Solutions Corp., ValueLink, Unum ID Inc. (“Verified Inc.“), Vesta Innovations, Inc. and Xactus, LLC. Their respective privacy policies can be found on their
          websites. You accept that we may share your information with these data partners so they can give us the insight we need into things like your finances, property,
          employment, etc. that are needed for your loan application. The information we share includes, but is not limited to, your full name, birth date, address, telephone
          number and social security number (only where necessary). In the event you are directed to or use a third party company&apos;s website, these third party websites have
          their own privacy policies, including cookies, and we urge you to review them. They will govern the use of personal information you submit or are collected by cookies
          whilst visiting these websites. We do not accept any responsibility or liability for the privacy practices of such third party websites and your use of such websites is
          at your own risk.
        </li>
        <li>
          <b>Service alerts and critical notices:</b> Although we respect and honor the privacy preferences you have expressed, we may need to contact you to inform you of specific
          changes that may impact your ability to use this service or for other critical non-marketing purposes, such as bug alerts. We may also contact you to respond to your
          specific requests, to clarify the order of information you gave us, or to let you know about upcoming subscription expiration dates.
        </li>
        <li>
          <b>Change of control:</b> Your personally identifiable information may be transferred in connection with a sale, merger, transfer, exchange or other disposition (whether
          of assets, stock or otherwise) of all or a portion of a business of Beeline. You will have the opportunity to opt out of further secondary use of your information
          following any change of control.
        </li>
        <li>
          <b>Changes to our privacy policy:</b> If we plan to make significant changes to any of our privacy policies or practices with respect to how we use personally
          identifiable information, we&apos;ll post those changes to the Beeline website. Your continued use of the website, portal or mobile application after the updates are
          posted represents your consent to the terms of the new Privacy Policy. In countries outside of the United States, we will also take other steps as required by law, such
          as obtaining your consent or providing you with the opportunity to delete your personal information.
        </li>
        <li>
          <b>Legal disclosures:</b> In some cases we may disclose certain information to comply with a legal process such as a court order, subpoena, search warrant, or law
          enforcement request.
        </li>
        <li>
          <b>Hosting:</b> Our website, portal and mobile applications are hosted by a third party service provider on servers located in the United States. If you are in a non-US
          jurisdiction, you may be sending your personal information to the United States, which may or may not have laws that provide an equivalent level of data protection to the
          laws of your home country.
        </li>
      </ul>

      <Title>WE TELL YOU HOW AND WHY WE USE WEB TECHNOLOGIES.</Title>
      <Paragraph>Here is how and why we use some common web technologies to help manage our websites:</Paragraph>

      <ul>
        <li>
          <b>Cookies:</b> A &quot;cookie&quot; is a small piece of information that our website may provide to your browser while you are at our sites. We may use web-based tools,
          like cookies to see which areas of our site users prefer (e.g., based on the number of visits to those areas) which will help us better market to individual consumers.
          This is done through a tracking utility that allows us to reconstruct activity from a session or by a user, for troubleshooting and issue resolution purposes. Beeline may
          also employ service providers to help us collect and understand our website usage data. These service providers may also use cookies.We do not process information through
          cookies to identify a particular individual. Cookies are stored on your device and you have full access and control over their use. You may deactivate or restrict the
          transmission of cookies by changing the settings of your web browser. Cookies that are already stored may be deleted at any time. If you visit our website with cookies
          deactivated, you may not be able to use all the functions of our site fully. When periodic surveys are presented to website visitors, cookies are used to prevent issuing
          multiple invitations to the same individual.
        </li>
        <li>
          If you are a registered member on the Beeline website, you will receive a cookie when you log in. This cookie is maintained by your browser and contains your identifier.
          This cookie is also used to authenticate your identity and provide you with access to areas of our website that are limited to registered members, such as those that
          allow you to view and manage your personal profile.
        </li>
        <li>
          Additionally, Beeline service providers that serve ads on affiliate and/or advertiser websites may assign different cookies and small graphical images called single-pixel
          GIFs or web beacons, to your browser to track the effectiveness of our advertising on other websites and your involvement with Beeline. We may also employ service
          providers who may assign cookies or web beacons to your browser to assist us in collecting website usage data such as your IP address, session ID, URL and demographic
          information such as your zip code. The collection of data may include personally identifiable information. We do not track URLs that you type into your browser, nor do we
          track you across the internet once you leave our site.
        </li>
        <li>
          Your web browser may be set to accept cookies automatically, but can be changed to decline them. If you wish to remove cookies provided by Beeline, please check your
          browser&apos;s settings and its Help section for instructions for removing cookies from your browser. If you decline to accept cookies, then you may be required to
          re-register each time you download or request materials from the website.
        </li>
        <li>
          We may use internal or third party advertising companies to collect non-personally identifiable information for online behavioral advertising to provide targeted display
          advertisements, through participating publishers and advertisers, that are likely to be of greater interest to you. Such information may include non-personally
          identifiable information (e.g., click stream information, browser type, time and date, subject of advertisements clicked or scrolled over) through the use of cookies and
          other technology.
        </li>
        <li>
          <b>Website usage data:</b> Our website tracks usage data, including, for example, your IP address, your browser type and version, which pages you view, which page, if
          any, linked you to our site, and which link, if any, you follow off of our site. We use this data in the aggregate and on an individual level to better understand website
          activity to improve our site offerings, to reconstruct activity from a session or by a user, for troubleshooting and issue resolution purposes. We may also use this data
          to provide you a more personalized website experience, assistance with technical support questions, to send you special offers, product and service updates, or other
          promotional materials that are relevant and tailored to your interests. If you do not want to receive these offers or promotions, simply indicate your contact preferences
          during the registration process, within any future communications to us or by sending an email to&nbsp;
          <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.OPTOUT}`} selected>
            {CONST.LINKS.EXTERNAL.EMAIL.OPTOUT}
          </ButtonText>
          . We do not share your information with outside companies for their promotional use, however we do use certain functionality from Google and others for data analytics and
          advertising us and our products on the internet. We have implemented the following features with Google: Tag Manager, Analytics, Optimize & Ads.
        </li>
        <li>
          <b>Social media sites and Marketing security practices:</b> We use social media to connect with and market to the public, our customers, and potential customers of the
          Beeline family. Your activity on third party websites is governed by the security and privacy policies of each third party website. Users of third party websites often
          share information with the general public, user community, and/or the third party vendors operating these websites, which may use this information in a variety of ways.
          Consequently, you should review the privacy policies of the third party website before using it and ensure that you understand how this information may be used. You may
          be able to adjust privacy settings on your accounts on any third party website or application to match your preferences. The information posted on or directed at us
          through social media is generally available to the public. To protect your privacy, do not include information you want to keep private or any other sensitive personal
          information in your social media activity, comments or responses. This information may be archived independently on, and retention of such information is governed by the
          third party website. Additionally, we may use third party marketing platforms and other data analytics tools to analyze your preferences and certain transaction details.
          In order to maximize these capabilities, we may store some data about you on those platforms.
        </li>
      </ul>

      <Title>USE OF PERSONAL INFORMATION</Title>
      <Paragraph>
        We give you the opportunity to control the use of your personal information for purposes outside of completing and servicing your loan. For example, occasionally, we may
        use your contact information to send you promotional communications about Beeline products, including those of our affiliates. These communications may include email,
        telephone, texts and direct mail. If you don&apos;t wish to receive these, you can tell us your contact preferences during the information collection process, or you can
        call us at&nbsp;
        <ButtonText to={`tel:${CONST.LINKS.EXTERNAL.PHONE.BEELINE_POPUP}`} selected>
          {CONST.LINKS.EXTERNAL.PHONE.BEELINE_PLAIN}
        </ButtonText>
        &nbsp; or send an email to&nbsp;
        <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.OPTOUT}`} selected>
          {CONST.LINKS.EXTERNAL.EMAIL.OPTOUT}
        </ButtonText>
        .
      </Paragraph>
      <Paragraph>
        We use Google for certain marketing purposes. If you wish to opt out of Google&apos;s use of your information for marketing purposes, you may opt out through this
        link&nbsp;
        <ButtonText to="https://tools.google.com/dlpage/gaoptout/" newWindow selected>
          https://tools.google.com/dlpage/gaoptout/
        </ButtonText>
        &nbsp; and follow the instructions on the website.
      </Paragraph>
      <Paragraph>
        Additionally, you can manage your ad preferences with Google through the following link&nbsp;
        <ButtonText to="https://adssettings.google.com/" newWindow selected>
          https://adssettings.google.com/
        </ButtonText>
      </Paragraph>
      <Paragraph>To help illustrate how we use your personal information, see the handy chart below:</Paragraph>
      <Table>
        <THead>
          <Row>
            <HeadCell>Reason we share personal information</HeadCell>
            <HeadCell>Who would information be shared with?</HeadCell>
            <HeadCell>Does Beeline share?</HeadCell>
            <HeadCell>Reason for Sharing</HeadCell>
            <HeadCell>Can you opt out?</HeadCell>
          </Row>
        </THead>
        <TBody>
          <Row>
            <Cell>For everyday business purposes (process your transaction, respond to legal investigations, court orders or report to credit bureaus)</Cell>
            <Cell>Us/third parties we must share with</Cell>
            <Cell>Yes</Cell>
            <Cell>Business purpose</Cell>
            <Cell>No</Cell>
          </Row>
          <Row>
            <Cell>For our marketing purposes (to offer our products and services to you; which may include sharing with third party systems for personalization, etc.)</Cell>
            <Cell>Us</Cell>
            <Cell>Yes</Cell>
            <Cell>Marketing</Cell>
            <Cell>Yes</Cell>
          </Row>
          <Row>
            <Cell>For Beeline&apos;s group of affiliated companies&apos; everyday business purposes (information about your transaction, creditworthiness and experiences)</Cell>
            <Cell>Beeline&apos;s family of companies</Cell>
            <Cell>Yes</Cell>
            <Cell>Business purpose</Cell>
            <Cell>No</Cell>
          </Row>
          <Row>
            <Cell>For Beeline&apos;s group of affiliated companies&apos; marketing purposes</Cell>
            <Cell>Beeline&apos;s family of companies</Cell>
            <Cell>Yes</Cell>
            <Cell>Marketing</Cell>
            <Cell>Yes</Cell>
          </Row>
          <Row>
            <Cell>For third party business purposes (information about your transaction, creditworthiness and experiences)</Cell>
            <Cell>Third parties</Cell>
            <Cell>Yes</Cell>
            <Cell>Business purpose</Cell>
            <Cell>No</Cell>
          </Row>
          <Row>
            <Cell>For third parties to market to you (including co-marketing with other financial companies)</Cell>
            <Cell>Third parties</Cell>
            <Cell>Yes</Cell>
            <Cell>Marketing</Cell>
            <Cell>Yes</Cell>
          </Row>
        </TBody>
      </Table>

      <Paragraph>
        If you are applying for any of our products with a co-borrower, there will be a single login for both of you. As the primary applicant, you agree and understand that you
        must fully communicate with the co-borrower throughout the process on the transaction and the co-borrower must authorize their credit pull themselves as well as use their
        own credentials for accessing their bank, asset, tax or employment details. Beeline will consider the receipt of the co-borrower&apos;s information, credentials and credit
        pull authorization as the authorization of such co-borrower and you will hold us harmless from any action of the co-borrower in the event this provision is not strictly
        followed. If you have been named a co-borrower on a loan product and believe this is an error or fraud, please reach out to us at&nbsp;
        <ButtonText to={`tel:${CONST.LINKS.EXTERNAL.PHONE.BEELINE_POPUP}`} selected>
          {CONST.LINKS.EXTERNAL.PHONE.BEELINE_PLAIN}
        </ButtonText>
        &nbsp; or via email at:&nbsp;
        <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}`} selected>
          {CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}
        </ButtonText>
        .
      </Paragraph>

      <Title>Special notice for California residents</Title>
      <Paragraph>
        The California Consumer Privacy Act provides California residents with specific rights regarding their personal information. Please see our&nbsp;
        <ButtonText to={CONST.LINKS.MAIN.LEGAL.PRIVACY_CALIFORNIA} selected>
          Privacy Notice (California Residents)
        </ButtonText>{' '}
        for more information.
      </Paragraph>

      <Title>ABILITY TO UPDATE OR CORRECT YOUR PERSONALLY IDENTIFIABLE INFORMATION.</Title>
      <Paragraph>
        You can update or correct the contact and loan application information that you&apos;ve given us, at any time. You may review and update information stored in your
        application online until you submit your application. Once you have submitted your application, you may only change the information by directly contacting our operations
        team at &nbsp;
        <ButtonText to={`tel:${CONST.LINKS.EXTERNAL.PHONE.BEELINE_POPUP}`} selected>
          {CONST.LINKS.EXTERNAL.PHONE.PLAIN}
        </ButtonText>
        &nbsp; or emailing us at&nbsp;
        <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.CHANGEINFO}`} selected>
          {CONST.LINKS.EXTERNAL.EMAIL.CHANGEINFO}
        </ButtonText>
        .
      </Paragraph>
      <Paragraph>
        Beeline recommends that you do not use email communications to send us sensitive personal information. If you need to change that personal information, you should call us
        directly.
      </Paragraph>
      <Paragraph>
        You may have rights in non-U.S. countries, such as objecting to, and blocking the processing of your personal information. For security reasons, Beeline will take steps to
        authenticate your identity before providing you with access to personal information.
      </Paragraph>

      <Title>HOW WE PROTECT YOUR INFORMATION</Title>
      <Paragraph>
        We maintain physical, electronic and procedural safeguards to protect your non-public personal information. Encryption technology is used when loan applications and other
        non-public personal information is transmitted over the internet. We restrict access to non-public personal information to those employees who need to know that information
        to provide our services to you. Our employees are bound by a Confidentiality Agreement requiring confidential treatment of all such information. We train our employees in
        security procedures and we conduct audits to verify compliance with these procedures.
      </Paragraph>
      <Paragraph>
        Additionally, we use industry-recognized security safeguards to protect your information from loss, misuse or unauthorization alteration. We use SSL transaction protocol
        for all email communication as well as strong encryption products, and use AWS hosting services.
      </Paragraph>

      <Title>WAYS TO CONTACT US ABOUT SECURITY AND PRIVACY.</Title>
      <Paragraph>
        Questions regarding our website&apos;s privacy statement, our online Privacy Policy, our information practices or other aspects of privacy on our websites, should be
        directed to us at&nbsp;
        <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}`} selected>
          {CONST.LINKS.EXTERNAL.EMAIL.PRIVACY}
        </ButtonText>
        &nbsp; or in writing to Beeline Loans, Inc., Attn: Privacy Officer,&nbsp;
        {CONST.ADDRESS}. You may also call us at&nbsp;
        <ButtonText to={`tel:${CONST.LINKS.EXTERNAL.PHONE.BEELINE_POPUP}`} selected>
          {CONST.LINKS.EXTERNAL.PHONE.BEELINE_PLAIN}
        </ButtonText>
        .
      </Paragraph>
    </Container>
  </NavOnlyTemplate>
);

export default PrivacyAndSecurity;
